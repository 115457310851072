import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {
  Columns,
  CustomPagination,
  onSortingChange,
  ProfilesSearchAddRemoveToolbar,
  renderValueFromEnum,
  selectionChange
} from '../components/dataGrid';
import {areasActions, groupsActions, issuesActions, usersActions} from '../redux/actions';
import {generateEmptyErrorsAndValidation} from '../config/validationRules';
import {validateForm} from '../helpers';
import {
  EditPageContainer,
  IssueContainer,
  IssueData,
  IssueHeader, IssueLink,
  IssuesContainer,
  IssuesList,
  IssuesTitle,
  IssueText,
  StyledContainer,
  StyledDataGrid
} from '../components/styledComponents';
import {Box, Button, Grid, TextField} from '@mui/material';
import strings from '../config/strings';
import selectOptions from '../config/selectOptions';
import {useDebounce} from '../components/hooks';
import {getBooleanField, getSelectField, getSelectMultipleField, getTextField} from '../components/fieldsInItem';
import {renderIssuesSkeleton, renderItemSkeleton} from '../components/skeletons';
import * as dateFns from 'date-fns';
import {renderText, renderUserLink} from '../components/renderFields';
import {ConfirmRemovingModal} from '../components/modal';

export const UsersScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const model = useSelector(state => state.users);
  const columns = [
    Columns.id(renderUserLink),
    Columns.type(renderValueFromEnum(selectOptions.profile.type)),
    Columns.email(renderText),
    Columns.firstName(renderText),
    Columns.lastName(renderText),
    // Columns.middleName(renderCellExpand),
    // Columns.phone(renderText),
    Columns.sex(renderValueFromEnum(selectOptions.profile.sex)),
  ];

  const baseActions = usersActions;
  useEffect(() => {
      dispatch(baseActions.getList(model.params));
    }, [model.page, model.params.order, model.params.ordering]
  );
  const rowSelected = ({id}) => {
    navigate(`/users/${id}`);
  };

  const [searchText, setSearchText] = useState('');
  useEffect(() => {
    model.params.search = searchText;
    model.params.offset = 0;
    dispatch(usersActions.setParams(model.params));
    dispatch(usersActions.setPage(1));
  }, [useDebounce(searchText, 800)]);

  useEffect(() => {
      dispatch(usersActions.getList(model.params));
    }, [model.page, model.params.ordering, model.params.search]
  );

  const [removing, setRemoving] = useState(false);
  const handleRemove = () => {
    setRemoving(true);
  };
  const closeRemoving = () => {
    setRemoving(false);
  };

  const confirmRemove = () => {
    dispatch(usersActions.removeList({
      ids: model.selected
    })).then(() => {
        closeRemoving();
        dispatch(usersActions.unselect());
        dispatch(usersActions.clear());
        dispatch(usersActions.resetParams());
        dispatch(usersActions.getList(model.params));
      }
    );
  };

  return (
    <div style={{height: '90%', width: '100%'}}>
      {!model.loading && model.selected ?
        <ConfirmRemovingModal
          open={!!removing}
          onClose={closeRemoving}
          onConfirm={confirmRemove}
          text={'Удалить выбранных пользователей?'}
        /> : ''
      }
      <StyledDataGrid
        getRowClassName={() => 'styled'}
        disableSelectionOnClick
        checkboxSelection={true}
        keepNonExistentRowsSelected
        disableColumnFilter
        disableColumnMenu
        columns={columns}
        rows={model.list}
        onSortModelChange={onSortingChange(model, dispatch, baseActions)}
        filterMode="server"
        onRowClick={rowSelected}
        sortingMode="server"
        selectionModel={model.selected}
        onSelectionModelChange={selectionChange(model, dispatch, baseActions)}
        loading={model.loading}
        components={{
          Toolbar: ProfilesSearchAddRemoveToolbar,
          Pagination: CustomPagination(model, dispatch, baseActions),
        }}
        componentsProps={{
          toolbar: {
            path: 'users',
            handleRemove: handleRemove,
            value: searchText,
            onChange: (event) => setSearchText(event.target.value),
            clearSearch: () => setSearchText(''),
          },
        }}
        localeText={{
          footerRowSelected: (count) =>
            `Выбрано элементов - ${count.toLocaleString()}`,
        }}
      />
    </div>
  );
};

const {fieldsInitState, fieldsValidators} = generateEmptyErrorsAndValidation([
  'username',
  'email',
  // 'number',
  'firstName',
  'lastName',
  // 'middleName',
]);

export const UserEditScreen = () => {
  const dispatch = useDispatch();
  const {id} = useParams();
  const navigate = useNavigate();

  const model = useSelector(state => state.users);
  const groups = useSelector(state => state.groups);
  const areas = useSelector(state => state.areas);
  const issues = useSelector(state => state.issues);

  const [state, setState] = useState(fieldsInitState);

  const [password, setPassword] = useState(null);
  const [passwordConfirm, setPasswordConfirm] = useState(null);
  const [passwordConfirmError, setPasswordConfirmError] = useState(null);

  const handleChange = (field) => (e) => {
    setState(prevState => ({
      ...prevState, [field]: {
        'error': false,
        'helperText': null,
      }
    }));
    let updated = model.item;
    updated[field] = e.target?.value;
    dispatch(usersActions.editItem(updated));
  };

  const handleCheckboxChange = (field) => (e) => {
    let updated = model.item;
    updated[field] = e.target.checked;
    // Галочка либо на JTI, либо BAT, либо PMI
    if (e.target.checked) {
      if (field === 'isJti') {
        updated['isBat'] = false;
        updated['isPmi'] = false;
      }
      if (field === 'isBat') {
        updated['isJti'] = false;
        updated['isPmi'] = false;
      }
      if (field === 'isPmi') {
        updated['isBat'] = false;
        updated['isJti'] = false;
      }
    }
    dispatch(usersActions.editItem(updated));
  };

  const handleSelectChange = (field) => (e) => {
    let updated = model.item;
    updated[field] = e.target.value;
    dispatch(usersActions.editItem(updated));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = validateForm(model.item, fieldsValidators, setState);
    if (data && !passwordConfirmError) {
      dispatch(usersActions.updateItem(id, data)).then(() => {
        location.reload();
      });
    }
  };

  const handleCancel = () => {
    dispatch(usersActions.editItem({}));
    navigate('/users');
  };

  const getPasswordWithConfirmation = () => {

    const verifyPasswordConfirm = (e) => {
      setPasswordConfirmError(null);
      if (e.target.value !== password) {
        setPasswordConfirmError('Пароли не совпадают');
      }
      setPasswordConfirm(e.target?.value);
      (handleChange('password'))(e);
    };

    const verifyPassword = (e) => {
      setPasswordConfirmError(null);
      if (!!passwordConfirm && e.target?.value !== passwordConfirm) {
        setPasswordConfirmError('Пароли не совпадают');
      }
      setPassword(e.target?.value);
    };

    return (
      <Grid item xs={12}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            type="password"
            label={'Пароль'}
            name={'password'}
            value={password || ''}
            error={!!passwordConfirmError}
            helperText={passwordConfirmError}
            onChange={verifyPassword}
          />
        </Grid>
        <Grid mt={2} item xs={12}>
          <TextField
            fullWidth
            type="password"
            size="small"
            variant="outlined"
            label={'Подтверждение пароля'}
            name={'passwordConfirm'}
            value={passwordConfirm || ''}
            error={!!passwordConfirmError}
            helperText={passwordConfirmError}
            onChange={verifyPasswordConfirm}
          />
        </Grid>
      </Grid>
    );
  };

  const renderIssue = (issue) => {
    return issue ? (<IssueContainer
      onClick={() => {
        navigate(`/issues/${issue.id}`);
      }}
    >
      <IssueHeader>
        <IssueLink href={`/issues/${issue.id}`} target={'_blank'}>№{issue.number}</IssueLink>
        <IssueText>{dateFns.format(issue.createdAt, 'yyyy.MM.dd HH:mm:SS')}</IssueText>
      </IssueHeader>
      <IssueData>
        Статус - {issue.status ? issue.status.name : ''}
        <br/>
        Категория - {issue.category ? issue.category.name || '-' : ''}
      </IssueData>
    </IssueContainer>) : '';
  };

  const [removing, setRemoving] = useState(false);
  const handleRemove = () => {
    setRemoving(true);
  };
  const closeRemoving = () => {
    setRemoving(false);
  };

  const confirmRemove = () => {
    dispatch(usersActions.removeItem(id)).then(() => {
      setRemoving(false);
      navigate('/users');
    });
  };

  useEffect(() => {
      dispatch(usersActions.getItem(id)).then(res => {
        dispatch(issuesActions.getList({userId: id, ordering: '-id'}));
        dispatch(groupsActions.getList({}));
        dispatch(areasActions.getList({}));
      });
    }, []
  );

  return (
    <EditPageContainer>
      {!model.loading && model.item ?
        <ConfirmRemovingModal
          open={!!removing}
          onClose={closeRemoving}
          onConfirm={confirmRemove}
          text={'Удалить текущего пользователя?'}
        /> : ''
      }
      <StyledContainer maxWidth="xs" sx={{
        height: 'auto'
      }}>
        {!model.loading && model.item ?
          <form onSubmit={handleSubmit}>
            <Box sx={{flexGrow: 1}}>
              <Grid container spacing={4}>

                {/* TODO avatar */}
                {/*{getRelatedImageField(model, 'image', handleChange)}*/}
                {getTextField(model, state, handleChange, 'id', {disabled: true})}
                {getSelectField(
                  selectOptions.profile.type,
                  model,
                  state,
                  handleSelectChange, 'type', 'title', ['title'],
                  {
                    required: true,
                    disabled: false
                  })}
                {/*{getTextField(model, state, handleChange, 'username')}*/}
                {getTextField(model, state, handleChange, 'email', {disabled: true})}
                {/*{getTextField(model, state, handleChange, 'number', {disabled: true})}*/}

                {getPasswordWithConfirmation()}

                {getSelectField(selectOptions.profile.sex, model, state, handleSelectChange, 'sex')}

                {getTextField(model, state, handleChange, 'lastName')}
                {getTextField(model, state, handleChange, 'firstName')}
                {/*{getTextField(model, state, handleChange, 'middleName')}*/}

                {getBooleanField(model, state, handleCheckboxChange, 'isBlocked')}
                {getBooleanField(model, state, handleCheckboxChange, 'isJti')}
                {getBooleanField(model, state, handleCheckboxChange, 'isBat')}
                {getBooleanField(model, state, handleCheckboxChange, 'isPmi')}

                {getSelectMultipleField(groups, model, state, handleSelectChange, 'groups', 'name', ['id', 'name'])}
                {getSelectField(areas, model, state, handleSelectChange, 'area', 'name', ['id', 'name'])}

                <Grid container={true} mt={4} mb={4} justifyContent={'space-around'}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {strings.button.save}
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={handleRemove}
                  >
                    {strings.button.remove}
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={handleCancel}
                  >
                    {strings.button.cancel}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form> : renderItemSkeleton()
        }
      </StyledContainer>
      <IssuesContainer>
        <IssuesTitle>
          История обращений
        </IssuesTitle>
        {
          issues.loading ?
            renderIssuesSkeleton()
            :
            <IssuesList>
              {(issues.list || []).map(e => renderIssue(e))}
              {!issues.list.length && '...'}
            </IssuesList>
        }
      </IssuesContainer>
    </EditPageContainer>
  );
};

export const UserAddScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const model = useSelector(state => state.users);
  const groups = useSelector(state => state.groups);
  const areas = useSelector(state => state.areas);

  const [state, setState] = useState(fieldsInitState);

  const [password, setPassword] = useState(null);
  const [passwordConfirm, setPasswordConfirm] = useState(null);
  const [passwordConfirmError, setPasswordConfirmError] = useState(null);

  const handleChange = (field) => (e) => {
    setState(prevState => ({
      ...prevState, [field]: {
        'error': false,
        'helperText': null,
      }
    }));
    let updated = model.item;
    updated[field] = e.target?.value;
    dispatch(usersActions.editItem(updated));
  };

  const handleSelectChange = (field) => (e) => {
    let updated = model.item;
    updated[field] = e.target.value;
    dispatch(usersActions.editItem(updated));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = validateForm(model.item, fieldsValidators, setState);
    if (data && !passwordConfirmError) {
      dispatch(usersActions.addItem(data)).then(() => {
        navigate('/users');
      });
    }
  };

  const handleCancel = () => {
    dispatch(usersActions.editItem({}));
    navigate('/users');
  };

  const handleCheckboxChange = (field) => (e) => {
    let updated = model.item;
    updated[field] = e.target.checked;
    // Галочка либо на JTI, либо BAT, либо PMI
    if (e.target.checked) {
      if (field === 'isJti') {
        updated['isBat'] = false;
        updated['isPmi'] = false;
      }
      if (field === 'isBat') {
        updated['isJti'] = false;
        updated['isPmi'] = false;
      }
      if (field === 'isPmi') {
        updated['isBat'] = false;
        updated['isJti'] = false;
      }
    }
    dispatch(usersActions.editItem(updated));
  };

  useEffect(() => {
      dispatch(usersActions.editItem({}));
      dispatch(groupsActions.getList({}));
      dispatch(areasActions.getList({}));
    }, []
  );

  const getPasswordWithConfirmation = (required = false) => {

    const verifyPasswordConfirm = (e) => {
      setPasswordConfirmError(null);
      if (e.target.value !== password) {
        setPasswordConfirmError('Пароли не совпадают');
      }
      setPasswordConfirm(e.target?.value);
      (handleChange('password'))(e);
    };

    const verifyPassword = (e) => {
      setPasswordConfirmError(null);
      if (!!passwordConfirm && e.target?.value !== passwordConfirm) {
        setPasswordConfirmError('Пароли не совпадают');
      }
      setPassword(e.target?.value);
    };

    return (
      <Grid item xs={12}>
        <Grid item xs={12}>
          <TextField
            required={required}
            fullWidth
            size="small"
            variant="outlined"
            label={'Пароль'}
            name={'password'}
            value={password || ''}
            error={!!passwordConfirmError}
            helperText={passwordConfirmError}
            onChange={verifyPassword}
          />
        </Grid>
        <Grid mt={2} item xs={12}>
          <TextField
            required={required}
            fullWidth
            size="small"
            variant="outlined"
            label={'Подтверждение пароля'}
            name={'passwordConfirm'}
            value={passwordConfirm || ''}
            error={!!passwordConfirmError}
            helperText={passwordConfirmError}
            onChange={verifyPasswordConfirm}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <EditPageContainer>
      <StyledContainer maxWidth="xs">
        {model.item ?
          <form onSubmit={handleSubmit}>
            <Box sx={{flexGrow: 1}}>
              <Grid container spacing={4}>

                {getSelectField(
                  selectOptions.profile.type,
                  model,
                  state,
                  handleSelectChange,
                  'type', 'title', ['title'],
                  {required: true}
                )}
                {/*{getTextField(model, state, handleChange, 'username')}*/}
                {getTextField(model, state, handleChange, 'email', {required: true})}

                {getPasswordWithConfirmation()}

                {/*{getTextField(model, state, handleChange, 'number')}*/}
                {getSelectField(selectOptions.profile.sex, model, state, handleSelectChange, 'sex')}

                {getTextField(model, state, handleChange, 'lastName')}
                {getTextField(model, state, handleChange, 'firstName')}
                {/*{getTextField(model, state, handleChange, 'middleName')}*/}

                {getBooleanField(model, state, handleCheckboxChange, 'isJti')}
                {getBooleanField(model, state, handleCheckboxChange, 'isBat')}
                {getBooleanField(model, state, handleCheckboxChange, 'isPmi')}

                {getSelectMultipleField(groups, model, state, handleSelectChange, 'groups', 'name', ['id', 'name'])}
                {getSelectField(areas, model, state, handleSelectChange, 'area', 'name', ['id', 'name'])}

                <Grid container={true} mt={4} mb={4} justifyContent={'space-around'}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {strings.button.save}
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={handleCancel}
                  >
                    {strings.button.cancel}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form> : ''
        }
      </StyledContainer>
    </EditPageContainer>
  );
};